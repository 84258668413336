<template>
  <LSection>
    <template #body>
      <div class="">
        <div class="flex flex-col items-center justify-center mb-16">
          <div class="w-full">
            <TabNavigation :tabs="tabs" />
            <div class="body relative mb-12">
              <router-view name="accordsTab"></router-view>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LSection>
</template>

<script>
import { ref } from "vue";
import LSection from "@/components/layout/LSection.vue";
import TabNavigation from "@/components/TabNavigation.vue";

export default {
  name: "Accords",
  components: { LSection, TabNavigation },
  setup() {
    const tabs = ref([
      {
        name: "About Accords",
        to: { name: "aboutAccords" },
        routeName: "aboutAccords",
      },
      {
        name: "Educational Accords",
        to: { name: "educationalAccords" },
        routeName: "educationalAccords",
      },
      {
        name: "Industrial Accords",
        to: { name: "industrialAccords" },
        routeName: "industrialAccords",
      },
      {
        name: "Submit an accord",
        to: { name: "submitAccord" },
        routeName: "submitAccord",
      },
    ]);

    return {
      tabs,
    };
  },
};
</script>
